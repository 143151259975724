import Vue from "vue";

/*TITLE*/
document.title = "Residencial La Fusteria de Torroella | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Residencial La Fusteria de Torroella";
Vue.prototype.$subtitle = "Al centre de Torroella de Montgrí.";

/*INTRO*/
Vue.prototype.$promoter = "Residencial La Fusteria de Torroella";
Vue.prototype.$introSubtitle = "Al centre de Torroella de Montgrí.";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-opc.-1-viena-eisenhut--20230116090135.jpg";
Vue.prototype.$image_kitchen = "cocina-opc.-3-pavimento-bottega-acero-revestimiento-china-blanco-frentes-roble-organico-encimera-xtone-bottega-caliza--20230116090142.jpg";
Vue.prototype.$image_bath1 = "banop-opc.-1-pavimento-bottega-acero-revestimiento-base-bottega-acero-revestimiento-decorativo-dover-modern-line-acero--20230116090102.jpg";
Vue.prototype.$image_bath2 = "banosec-opc.-1-pavimento-newport-natural-revestimiento-base-newport-natural-revestimiento-decorativo-old-natural--20230116090110.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-opc.-1-viena-eisenhut--20230116090135.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "cocina-opc.-3-pavimento-bottega-acero-revestimiento-china-blanco-frentes-roble-organico-encimera-xtone-bottega-caliza--20230116090142.jpg",
  },
  {
    src: "cocina-opc.-2-pavimento-bottega-acero-revestimiento-china-blanco-frentes-bison-encimera-xtone-bottega-acero--20230116090148.jpg",
  },
  {
    src: "cocina-opc.-1-pavimento-bottega-acero-revestimiento-china-blanco-frentes-blanco-nubol-encimera-xtone-bottega-acero--20230116090154.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banop-opc.-1-pavimento-bottega-acero-revestimiento-base-bottega-acero-revestimiento-decorativo-dover-modern-line-acero--20230116090102.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banosec-opc.-1-pavimento-newport-natural-revestimiento-base-newport-natural-revestimiento-decorativo-old-natural--20230116090110.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://360.porcelanosapartners.com/LA_FUSTERIA_DE_TORROELLA/";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://www.youtube.com/embed/FzCqRM-zLdE";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=torroella%20de%20montgrí%2017257%20girona",
    text: "Torroella de Montgrí, 17257 Girona",
  },
];

Vue.prototype.$showroom = [
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:936680803",
    text: "936680803",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@obrein.com",
    text: "info@obrein.com",
  },
];
